import React, {Component} from "react";
import {API} from "aws-amplify";

export default class UserInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const userInfo = await this.userInfo();
            console.log(userInfo);
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    userInfo() {
        return API.get("nuh", "/userInfo");
    }

    render() {
        return (
            <div className="Home">
                <div>Brukerinfo - sjekk console.log</div>
            </div>
        );
    }
}