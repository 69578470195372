import React, {Component} from "react";
import {API} from "aws-amplify";
import HomeButton from "../components/HomeButton";
import HistoryRow from "./HistoryRow";
import config from "../config";

export default class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            items: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const items = await this.items();
            this.setState({items});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    items() {
        return API.get("nuh", "/items");
    }

    rowClicked = (item) => {
        this.props.history.push("/items/" + item.itemId);
    };

    renderItemsList(items) {
        if (items.length > 0) {
            const list = [].concat(items).sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }).map((item) => {
                return (<HistoryRow item={item} rowClicked={this.rowClicked}/>);
            });
            return (
                <div style={{overflowX: 'auto'}}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Dato</th>
                            <th scope="col">{config.projectCodeTitle}</th>
                            <th scope="col">Referanse</th>
                            <th scope="col">Kjøpsbeskrivelse</th>
                            <th scope="col" className={"d-none d-md-block"}>Kvittering</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list}
                        </tbody>
                    </table>
                </div>
            );
        }
        return (
            <div>
                Ingen tidligere kjøp registrert.
            </div>
        )
    }

    renderItems() {
        return (
            <div className="items">
                <h1>Mine kjøp</h1>
                <div>
                    {this.state.isLoading && <i className="fas fa-spinner fa-spin"/>}
                    {!this.state.isLoading && this.renderItemsList(this.state.items)}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="content shadow">
                {this.renderItems()}
                <HomeButton/>
            </div>
        );
    }
}