import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import config from "./config";
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            userPhone: ''
        };
    }

    async componentDidMount() {
        try {
            const user = await Auth.currentSession();
            this.userHasAuthenticated(true, user);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = (authenticated, user) => {
        let userPhone = '';
        if (user && user.idToken && user.idToken.payload) {
            userPhone = user.idToken.payload.phone_number;
        } else if (user && user.attributes) {
            userPhone = user.attributes.phone_number;
        }
        userPhone = userPhone.replace('+47', '');
        this.setState({isAuthenticated: authenticated, userPhone: userPhone});
    };

    handleLogout = async event => {
        await Auth.signOut();

        this.userHasAuthenticated(false, null);

        this.props.history.push("/login");
    };

    renderVersionBar() {
        if (process.env.REACT_APP_STAGE === 'test') {
            return (
                <div className="alert alert-warning mb-0 text-center" role="alert">
                    TEST - APP
                </div>
            )
        }
    }

    renderUserInfo() {
        return (
            <li className="nav-item">
                <span className="navbar-text">
                {this.state.userPhone}
                </span>
            </li>
        )
    }

    renderNavbar() {
        if (this.state.isAuthenticated) {
            return (
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <a className="navbar-brand mr-auto" href="/">
                        <img src="/logo_farger.svg" height="30" alt="NUH! Logo"/>
                    </a>
                    <div className="mr-auto">
                        <span>{config.appHomeTitle}</span>
                    </div>
                    <div>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <button className="btn nav-link btn-sm btn-outline-secondary ml-2"
                                        style={{paddingLeft: '10px', paddingRight: '10px'}}
                                        onClick={this.handleLogout}>
                                    LOGG UT
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
            );
        }
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            userPhone: this.state.userPhone
        };
        return (
            !this.state.isAuthenticating &&
            <div>
                <div className="container-fluid">
                    {this.renderVersionBar()}
                    {this.renderNavbar()}
                </div>
                <div className="container-fluid mt-3">
                    <div className="mr-3 ml-3">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6">
                                <Routes childProps={childProps}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);

