import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {Auth} from "aws-amplify";
import config from "../config";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            showErrorMsg: false,
            phone: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.phone.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
            showErrorMsg: false
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const user = await Auth.signIn('+47' + this.state.phone, config.pwPrefix + '' + this.state.password);
            this.props.userHasAuthenticated(true, user);
        } catch (e) {
            console.log(e);
            if (e.code && (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException')) {
                this.setState({
                    showErrorMsg: true,
                    password: '',
                    isLoading: false
                });
            } else {
                alert(e.message);
                this.setState({isLoading: false});
            }
        }
    };

    renderErrorMsg() {
        if (this.state.showErrorMsg) {
            return (
                <div className="alert alert-danger mt-2" role="alert">
                    Feil brukernavn/passord. Vennligst prøv igjen.
                </div>
            )
        }
    }

    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col">
                    <div className="content shadow">
                        <div className="row justify-content-md-center mt-5 mb-5">
                            <div className="col-lg-10">
                                <img src="/logo_farger.svg" className="img-fluid" alt="NUH! Logo"
                                     style={{paddingLeft: "25%", paddingRight: "25%"}}/>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-2">
                            <div className="col-lg-10">
                                <div className="Login">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="phone">Telefon</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">+47</div>
                                                </div>
                                                <input type="number" className="form-control form-control-lg" id="phone"
                                                       value={this.state.phone}
                                                       onChange={this.handleChange} autoFocus={true}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Passord</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">{config.pwPrefix}</div>
                                                </div>
                                                <input type="password" className="form-control form-control-lg"
                                                       id="password"
                                                       value={this.state.password}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        {this.renderErrorMsg()}
                                        <div className="form-group">
                                            <div className="input-group mb-2">
                                                <LoaderButton
                                                    block
                                                    className="btn-block btn-lg btn-bluecolor"
                                                    disabled={!this.validateForm()}
                                                    type="submit"
                                                    isLoading={this.state.isLoading}
                                                    text="LOGG INN"
                                                    loadingText="VENNLIGST VENT…"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
