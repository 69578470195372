import React, {Component} from "react";
import {Storage} from "aws-amplify";
import moment from "moment";

export default class HistoryRow extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: true,
            item: null,
            attachmentURL: null
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const item = this.props.item;
            const {attachment} = item;

            if (attachment) {
                attachmentURL = await Storage.vault.get(attachment);
            }

            this.setState({
                item,
                attachmentURL
            });
        } catch (e) {
            alert(e);
        }
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    renderAttachment() {
        if (this.state.item && this.state.item.attachment) {
            return (
                <a target="_blank"
                   rel="noopener noreferrer"
                   href={this.state.attachmentURL}>
                    {this.formatFilename(this.state.item.attachment)}
                </a>
            );
        }
        return (
            <span>
                Ingen vedlegg
            </span>
        )
    }

    render() {
        const item = this.props.item;
        return (
            <tr onClick={() => this.props.rowClicked(item)} style={{cursor: "pointer"}}>
                <td>
                    <span className={"d-none d-md-block"}>{moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")}</span>
                    <span className={"d-md-none"}>{moment(item.createdAt).format("DD.MM.YYYY")}</span>
                </td>
                <td>{item.speedyId}</td>
                <td>{item.reqCode}</td>
                <td>
                    <span className="d-inline-block text-truncate" style={{maxWidth: "400px"}}>{item.content.trim().split("\n")[0]}</span>
                </td>
                <td className={"d-none d-md-block"}>{this.renderAttachment()}</td>
            </tr>
        );
    }
}