import React, {Component} from "react";
import {API} from "aws-amplify";
import HomeButton from "../components/HomeButton";
import LoaderButton from "../components/LoaderButton";

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            userInfo: {},
            userName: '',
            userPhone: this.props.userPhone,
            isSaving: false,
            somethingChanged: false
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const userInfo = await this.userInfo();
            this.setState({
                userInfo,
                userName: userInfo.userName,
                userPhone: userInfo.userPhone
            });
        } catch (e) {
            if (e && e.response && e.response.status === 500) {
                console.log('Do something');
            } else {
                alert(e);
            }
        }

        this.setState({isLoading: false});
    }

    validateForm() {
        return this.state.somethingChanged && !this.state.isLoading && !this.state.isSaving && this.state.userName.length > 0 && this.state.userPhone.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
            somethingChanged: true
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isSaving: true});

        try {
            await this.updateUserInfo({
                userName: this.state.userName,
                userPhone: this.state.userPhone
            });
            this.setState({
                isSaving: false,
                somethingChanged: false
            });
        } catch (e) {
            alert(e);
            this.setState({
                isSaving: false
            });
        }
    };

    updateUserInfo(item) {
        return API.post("nuh", "/users", {
            body: item
        });
    }

    userInfo() {
        return API.get("nuh", "/users");
    }

    renderProfileInfo(info) {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="userName">Navn</label>
                    <div className="input-group mb-2">
                        <input type="text" className="form-control" id="userName" value={this.state.userName}
                               onChange={this.handleChange} disabled={this.state.isLoading || this.state.isSaving}/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="userPhone">Telefon</label>
                    <div className="input-group mb-2">
                        <input type="text" className="form-control" id="userPhone" value={this.state.userPhone}
                               onChange={this.handleChange} disabled={this.state.isLoading || this.state.isSaving || true}/>
                    </div>
                </div>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    className="mr-2 btn-greencolor"
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="LAGRE"
                    loadingText="VENNLIGST VENT…"
                />
                <LoaderButton
                    block
                    bsSize="large"
                    className="btn-bluecolor"
                    disabled={this.state.isLoading || this.state.isSaving}
                    isLoading={this.state.isLoading}
                    text="TILBAKE"
                    type="button"
                    onClick={() => this.props.history.push("/")}
                />
            </form>
        );
    }

    render() {
        return (
            <div className="content shadow">
                <div className="items">
                    <h1>Min profil</h1>
                    <div className="row">
                        <div className="col-md-6">
                            {this.state.isLoading && <i className="fas fa-spinner fa-spin"/>}
                            {!this.state.isLoading && this.renderProfileInfo(this.state.userInfo)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}