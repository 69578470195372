import React, {Component} from "react";
import {API, Storage} from "aws-amplify";
import {s3Upload} from "../libs/awsLib";
import config from "../config";

export default class StoreInfo extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: true,
            item: null,
            speedyId: "",
            content: "",
            reqCode: "",
            attachmentURL: null
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const item = await this.getItem();
            const {content, reqCode, attachment, speedyId} = item;

            if (attachment) {
                attachmentURL = await Storage.vault.get(attachment);
            }

            this.setState({
                item,
                content,
                speedyId,
                reqCode,
                attachmentURL
            });
        } catch (e) {
            alert(e);
        }
        this.setState({isLoading: false});
    }

    getItem() {
        return API.get("nuh", `/items/${this.props.match.params.itemId}`);
    }

    validateForm() {
        return this.state.content.length > 0 && this.state.reqCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFileChange = event => {
        this.file = event.target.files[0];
        this.setState({
            fileName: this.file ? this.file.name : 'Scan kvittering'
        });
    };

    handleSubmit = async event => {
        let attachment;

        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({isLoading: true});

        try {
            if (this.file) {
                attachment = await s3Upload(this.file);
            }

            await this.updateItem({
                attachment: attachment || this.state.item.attachment,
                content: this.state.content,
                speedyId: this.state.speedyId,
                reqCode: this.state.reqCode
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    updateItem(item) {
        return API.put("nuh", `/items/${this.props.match.params.itemId}`, {
            body: item
        });
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    renderIconCol(icon) {
        return (
            <div className="col-2 text-center text-danger">
                <h2><i className={"fad fa-" + icon}/></h2>
            </div>
        )
    }

    renderSeperator() {
        return (
            <div className="row mt-3 mb-3">
                <div className="col text-center text-secondary">
                    <h3><i className={"fad fa-arrow-alt-circle-down"}/></h3>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="NewItem content shadow">
                    <i className="fas fa-spinner fa-spin"/>
                </div>
            );
        }
        return (
            <div className="NewItem mb-3">
                <div className="content shadow">
                    <div className="row">
                        {this.renderIconCol('browser')}
                        <div className="col">
                            <h4>Gå til:</h4>
                            <h4><span className={"text-danger"}>butikk.barentsit.no</span></h4>
                        </div>
                    </div>
                    {this.renderSeperator()}
                    <div className="row">
                        {this.renderIconCol('search')}
                        <div className="col">
                            <h4>Søk opp rekvisisjonskode:</h4>
                            <h4><span className="text-danger">{this.state.reqCode}</span></h4>
                            <p>OBS: Hvis koden ikke finnes er kjøpet ikke gyldig. Faktura vil bli avvist.</p>
                        </div>
                    </div>
                    {this.renderSeperator()}
                    <div className="row">
                        {this.renderIconCol('user-check')}
                        <div className="col">
                            <h4>Verifiser at informasjonen og den ansatte stemmer.</h4>
                        </div>
                    </div>
                    {this.renderSeperator()}
                    <div className="row">
                        {this.renderIconCol('copy')}
                        <div className="col">
                            <h4>Kopier "referanse" til bruk på faktura.</h4>
                            <h5 className={"mt-3"}><span className="text-danger">Ønsker faktura fortrinnsvis på EHF:</span></h5>
                            <p className={"mb-0"}>Org.nr: {config.companyOrgNr}</p>
                            <p>E-post: {config.companyEmail}</p>
                            <h5 className={"mt-3"}><span className="text-danger">Fakturaadresse:</span></h5>
                            <p className={"mb-0"}>{config.companyName}</p>
                            <p className={"mb-0"}>{config.companyAddress}</p>
                            <p>{config.companyPostalCode} {config.companyPostalCity}</p>
                        </div>
                    </div>
                    {this.renderSeperator()}
                    <div className="row">
                        {this.renderIconCol('file-invoice-dollar')}
                        <div className="col">
                            <h4>Fullfør handel, skriv ut kvittering fra ditt kassasystem og minn den ansatte på å scanne denne til kjøpet i appen.</h4>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <a className="btn btn-secondary"
                               href={`/items/${this.props.match.params.itemId}`}>Skjul</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
