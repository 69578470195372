import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {API} from "aws-amplify";
import config from "../config";

export default class NewItem extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            speedyId: "",
            content: "",
        };
    }

    validateForm() {
        return this.state.content.length > 0 && this.state.speedyId.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const item = await this.createItem({
                content: this.state.content,
                speedyId: this.state.speedyId
            });
            this.props.history.push("/items/" + item.itemId);
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    createItem(item) {
        return API.post("nuh", "/items", {
            body: item
        });
    }

    render() {
        return (
            <div className="NewItem">
                <div className="content shadow mb-2">
                    <div className="row justify-content-center">
                        <div className="col text-center">
                            <img src="/progress-1.png" className="img-fluid progress-img" alt="Progressbar - 1/4"/>
                        </div>
                    </div>
                </div>
                <div className="content shadow">
                    <h1>Nytt kjøp</h1>
                    <form onSubmit={this.handleSubmit} className={"mt-3"}>
                        <div className="form-group">
                            <label htmlFor="speedyId">{config.projectCodeTitle} <span className={"text-danger"}>*</span></label>
                            <input type="text" className="form-control" id="speedyId" required aria-required={true}
                                   value={this.state.speedyId} disabled={this.state.isLoading}
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Kjøpsbeskrivelse <span className={"text-danger"}>*</span></label>
                            <textarea className="form-control" id="content" value={this.state.content} placeholder={"Beskriv kort hva du skal kjøpe"}
                                      onChange={this.handleChange} disabled={this.state.isLoading} required aria-required={true}/>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="Third group">
                            <LoaderButton
                                block
                                bsSize="large"
                                className="btn-success"
                                disabled={this.state.isLoading}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="FORTSETT"
                                loadingText="VENNLIGST VENT…"
                            />
                        </div>
                        <div className="btn-group" role="group">
                            <LoaderButton
                                block
                                bsSize="large"
                                className="btn-bluecolor"
                                disabled={this.state.isLoading}
                                text="AVBRYT"
                                type="button"
                                onClick={() => this.props.history.push("/")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
