import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NewItem from "./containers/NewItem";
import Item from "./containers/Item";
import History from "./containers/History";
import Profile from "./containers/Profile";
import Settings from "./containers/Settings";
import UserInfo from "./containers/UserInfo";
import StoreInfo from "./containers/StoreInfo";
import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
        <AuthenticatedRoute path="/items/new" exact component={NewItem} props={childProps} />
        <AuthenticatedRoute path="/items/:itemId" exact component={Item} props={childProps} />
        <AuthenticatedRoute path="/items/:itemId/butikk" exact component={StoreInfo} props={childProps} />
        <AuthenticatedRoute path="/historikk" exact component={History} props={childProps} />
        <AuthenticatedRoute path="/profil" exact component={Profile} props={childProps} />
        <AuthenticatedRoute path="/innstillinger" exact component={Settings} props={childProps} />
        <AuthenticatedRoute path="/userinfo" exact component={UserInfo} props={childProps} />
        {/*<AuthenticatedRoute path="/items/:id" exact component={Items} props={childProps} />*/}
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>;