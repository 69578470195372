const test = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads-test"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://2xclu0fnte.execute-api.eu-central-1.amazonaws.com/test"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_tVGW7YGiq",
        APP_CLIENT_ID: "2ge06n0a7iiopi0o7ecq4hp2pb",
        IDENTITY_POOL_ID: "eu-central-1:d3e96a4e-09dd-4adb-89c1-2b110733bdf7"
    },
    appHomeTitle: 'Firma (TEST)',
    companyOrgNr: '123456789',
    companyEmail: 'test@test.no',
    companyName: 'Test AS',
    companyAddress: 'Testveien 1',
    companyPostalCode: '1234',
    companyPostalCity: 'Testbyen',
    reqPrefix: 'XXX',
    projectCodeTitle: 'Prosjektkode',
    pwPrefix: 'VK'
};

const prod = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://x1aa9s9fsb.execute-api.eu-central-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_tVGW7YGiq",
        APP_CLIENT_ID: "2ge06n0a7iiopi0o7ecq4hp2pb",
        IDENTITY_POOL_ID: "eu-central-1:d3e96a4e-09dd-4adb-89c1-2b110733bdf7"
    },
    appHomeTitle: 'VKE',
    companyOrgNr: '985519250',
    companyEmail: 'fakturamottak@varanger-kraft.no',
    companyName: 'Varanger Kraftentreprenør AS',
    companyAddress: 'Postboks 2742',
    companyPostalCode: '7439',
    companyPostalCity: 'Trondheim',
    reqPrefix: '112008',
    projectCodeTitle: 'Arbeidsordre-nr/Speedy-nr',
    pwPrefix: 'VK'
};

const sundquist = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads-sundquist"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://t3l5xrx0s6.execute-api.eu-central-1.amazonaws.com/sundquist"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_g0v5n6Oo0",
        APP_CLIENT_ID: "33dkaakq13mmo5bfo8l95lijqp",
        IDENTITY_POOL_ID: "eu-central-1:4ecb1c68-7b01-4c55-ad60-0d303345a47d"
    },
    appHomeTitle: 'Sundquist',
    companyOrgNr: '916425023',
    companyEmail: 'faktura@sundquist.no',
    companyName: 'AS Oscar Sundquist',
    companyAddress: 'Langfjordveien 62',
    companyPostalCode: '9910',
    companyPostalCity: 'Bjørnevatn',
    reqPrefix: '000',
    projectCodeTitle: 'Prosjektkode',
    pwPrefix: 'OS'
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : (process.env.REACT_APP_STAGE === 'sundquist' ? sundquist : test);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};