import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {API, Storage} from "aws-amplify";
import {s3Upload} from "../libs/awsLib";
import config from "../config";

export default class Item extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: true,
            item: null,
            speedyId: "",
            content: "",
            reqCode: "",
            attachmentURL: null
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const item = await this.getItem();
            const {content, reqCode, attachment, speedyId} = item;

            if (attachment) {
                attachmentURL = await Storage.vault.get(attachment);
            }

            this.setState({
                item,
                content,
                speedyId,
                reqCode,
                attachmentURL
            });
        } catch (e) {
            alert(e);
        }
        this.setState({isLoading: false});
    }

    getItem() {
        return API.get("nuh", `/items/${this.props.match.params.itemId}`);
    }

    validateForm() {
        return this.state.content.length > 0 && this.state.reqCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFileChange = event => {
        this.file = event.target.files[0];
        console.log(this.file);
        this.setState({
            fileName: this.file ? this.file.name : 'Scan kvittering'
        });
    };

    handleSubmit = async event => {
        let attachment;

        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({isLoading: true});

        try {
            if (this.file) {
                attachment = await s3Upload(this.file);
            }

            await this.updateItem({
                attachment: attachment || this.state.item.attachment,
                content: this.state.content,
                speedyId: this.state.speedyId,
                reqCode: this.state.reqCode
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    updateItem(item) {
        return API.put("nuh", `/items/${this.props.match.params.itemId}`, {
            body: item
        });
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    renderProgress() {
        let img = "/progress-2.png";
        if (this.state.item.attachment || this.state.fileName) {
            img = "/progress-3.png";
        }
        if (this.state.item && this.state.item.itemHandled) {
            img = "/progress-4.png";
        }
        return (
            <div className="content shadow mb-2">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <img src={img} className="img-fluid progress-img" alt="Progressbar - 2/4"/>
                    </div>
                </div>
            </div>
        );
    }

    renderAttachmentInfoBox() {
        if (!this.state.item.attachment) {
            if (this.state.fileName) {
                return (
                    <div className="form-group mt-2">
                        <label htmlFor="attachmentInfoBox">
                            <i className={'fal fa-thumbs-up mr-1 text-success'}/>Godt jobba! Husk å trykke lagre!
                        </label>
                    </div>
                );
            }
            return (
                <div className="form-group mt-2">
                    <label htmlFor="attachmentInfoBox">
                        <i className={'fal fa-exclamation-triangle mr-1 text-danger'}/>OBS! Mangler kvittering
                    </label>
                </div>
            );
        }
    }

    handleFilenameForInputBox(filename) {
        if (filename) {
            if (filename.length > 20) {
                return filename.substring(0, 20) + '...';
            }
            return filename;
        }
        return 'Scan kvittering';
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="NewItem content shadow">
                    <i className="fas fa-spinner fa-spin"/>
                </div>
            );
        }
        const refValue = config.reqPrefix + '/' + this.state.speedyId + '/' + this.state.reqCode;
        return (
            <div className="NewItem">
                {this.renderProgress()}
                <div className="content shadow">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group mb-0">
                            <label htmlFor="reqCode">Rekvisisjonskode:</label>
                        </div>
                        <div className="form-group">
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <div className="input-group req-code-container">
                                        <h1 className={"text-danger mb-0"}>{this.state.reqCode}</h1>
                                    </div>
                                </div>
                                <div className={"col-8 text-right"}>
                                    <a className="btn btn-outline-dark" href={`/items/${this.props.match.params.itemId}/butikk`}>
                                        Veiledning for butikk <i className={'fal fa-info-circle ml-1 text-danger'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="speedyId">
                                Referanse:<span><strong><em><u>{refValue}</u></em></strong></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="speedyId">{config.projectCodeTitle} <span className={"text-danger"}>*</span></label>
                            <input type="text" className="form-control" id="speedyId" required aria-required={true}
                                   value={this.state.speedyId} disabled={true}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Kjøpsbeskrivelse <span className={"text-danger"}>*</span></label>
                            <textarea className="form-control" id="content" value={this.state.content} required aria-required={true}
                                      onChange={this.handleChange} disabled={this.state.isLoading || (this.state.item && this.state.item.itemHandled)}/>
                        </div>
                        {this.state.item.attachment &&
                        <div className="form-group">
                            <label htmlFor="file">Kvittering</label>
                            <div className="input-group mb-3 form-control-plaintext">
                                <a target="_blank" className={"btn btn-outline-primary"}
                                   rel="noopener noreferrer"
                                   href={this.state.attachmentURL}>
                                    {this.formatFilename(this.state.item.attachment)}
                                </a>
                            </div>
                        </div>
                        }
                        {!this.state.item.attachment &&
                        <div className="form-group custom-file">
                            <input type="file" className="custom-file-input" id="file" accept="image/*" lang="en"
                                   onChange={this.handleFileChange} disabled={this.state.isLoading || (this.state.item && this.state.item.itemHandled)}/>
                            <label htmlFor="file"
                                   className="custom-file-label">{this.handleFilenameForInputBox(this.state.fileName)}</label>
                        </div>
                        }
                        {this.renderAttachmentInfoBox()}
                        <div className={"mt-3"}>
                            <div className="btn-group mr-2" role="group" aria-label="Third group">
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    className="btn-success"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Lagre"
                                    loadingText="Vennligst vent…"
                                />
                            </div>
                            <div className="btn-group" role="group">
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    className="btn-bluecolor"
                                    disabled={this.state.isLoading}
                                    text="Lukk"
                                    type="button"
                                    onClick={() => this.props.history.push("/historikk")}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
