import React, {Component} from "react";

export default class Home extends Component {
    constructor(props) {
        super(props);
    }

    menuItem = (text, url, icon) => {
        return (
            <div className="list-group mt-1">
                <a className="list-group-item list-group-item-action bgBlueColor" href={url}>
                    <i className={icon + ' mr-2'}/> {text.toUpperCase()}
                </a>
            </div>
        );
    };

    render() {
        return (
            <div className="Home content shadow mt-2">
                <div className="row justify-content-md-center">
                    <div className="col-md-6 text-left" style={{fontWeight: "bold"}}>
                        <div className="list-group mt-1">
                            <a className="list-group-item list-group-item-action bgRedColor" href="/items/new">
                                <i className={'fad fa-plus mr-2'}/> NYTT KJØP</a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center mt-2">
                    <div className="col-md-6 text-left">
                        {this.menuItem("Vis tidligere kjøp", "/historikk", "fad fa-history")}
                        {this.menuItem("Min profil", "/profil", "fad fa-user-alt")}
                        {this.menuItem("Innstillinger", "/innstillinger", "fad fa-cogs")}
                    </div>
                </div>
                <div className="row justify-content-md-center mt-5">
                    <div className="col-md-6 text-left">
                        <h4>Butikker:</h4>
                        <div className={"row"}>
                            <div className={"col"}>
                                <span>Montér</span>
                            </div>
                            <div className={"col"}>
                                <span>Biltema</span>
                            </div>
                            <div className={"col"}>
                                <span>Solar</span>
                            </div>
                            <div className={"col"}>
                                <span>TESS</span>
                            </div>
                            <div className={"col"}>
                                <span>Tools</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center mt-3">
                    <div className="col-md-6 text-left">
                        <h4>Spørsmål/hjelp?</h4>
                        <div className={"row"}>
                            <div className={"col"}>
                                <a className="btn btn-outline-primary" href="tel:90050847">
                                    <i className={'fad fa-phone mr-2'}/> RING OSS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}